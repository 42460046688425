<template>
    <div>
        <jy-query ref="form" :model="form" labelWidth="100" size="small">
            <jy-query-item label="调度单号" prop="scheduleId">
                <el-input v-model="form.scheduleId"></el-input>
            </jy-query-item>
            <jy-query-item label="状态" prop="status">
                <el-select v-model="form.status">
                    <el-option v-for="r in statusList" :key="r.status" :value="r.status" :label="r.statusStr"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="分拣中心名称" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车牌号" prop="plateNum">
                <el-input v-model="form.plateNum"></el-input>
            </jy-query-item>
            <jy-query-item label="司机" prop="driverNa">
                <el-input v-model="form.driverNa"></el-input>
            </jy-query-item>
            <jy-query-item label="调度发车日期" prop="planT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.planT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesDispatchTrackList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesDispatchTrackReset')">重置</el-button>
                <el-row>
                    <export-excel
                        v-if="btnexist('wastesDispatchTrackExport') && false"
                        baseUrl="/iwastes-admin"
                        url="/wasteOrder/export"
                        :params="form"
                        fileName="运输跟踪"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div>今日（{{ header.date }}）调度完成情况：待发车{{ header.wait }}，运输中{{ header.inTransit }}，已完成{{ header.finish }}</div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="tableData">
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                <jy-table-column label="调度单号" prop="scheduleId" show-overflow-tooltip>
                    <template v-slot="scope">
                        <el-button type="text" @click="navToDispatch(scope.row)">{{ scope.row.scheduleId }}</el-button>
                    </template>
                </jy-table-column>
                <jy-table-column label="状态">
                    <template v-slot="scope">{{ formatStatus(scope.row.status) }}</template>
                </jy-table-column>
                <jy-table-column label="分拣中心名称" show-overflow-tooltip>
                    <template v-slot="scope">{{ formatUnit(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column label="车牌号">
                    <template v-slot="scope">{{ scope.row.vehicleInfo.plateNum }}</template>
                </jy-table-column>
                <jy-table-column label="司机" prop="driverNa">
                    <template v-slot="scope">{{ formatDrivers(scope.row.driverList) }}</template>
                </jy-table-column>
                <jy-table-column label="调度发车时间" prop="pTime"></jy-table-column>
                <jy-table-column label="实际发车时间" prop="rTime"></jy-table-column>
                <jy-table-column label="完成运输时间" prop="fTime"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mixins: [btnMixins],
        methods: {
            navToDispatch(row) {
                this.handleDispatchView(row);
            },
            setOperateList() {
                let l = [
                    {
                        name: "查看调度单",
                        icon: "el-icon-edit-outline",
                        fun: this.handleDispatchView,
                        isShow: () => {
                            return this.btnexist("wastesDispatchTrackViewSch");
                        }
                    }
                ];
                this.operateList = l;
            },
            handleDispatchView(row) {
                this.$router.push({
                    name: "dOrder",
                    params: {
                        scheduleId: row.scheduleId
                    }
                });
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            getList() {
                let url = "/schedule/pageQuery";
                this.loading = true;
                this.$http
                    .post(
                        url,
                        {
                            ...this.form,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize,
                            pTimeStart: this.$util.addTime(this.form.planT && this.form.planT[0], true),
                            pTimeEnd: this.$util.addTime(this.form.planT && this.form.planT[1]),
                            planT: undefined
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.tableData = detail.list;
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            formatStatus(key) {
                const s = this.statusList.find(v => v.status === key);
                return s.statusStr;
            },
            formatUnit(key) {
                const u = this.unitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            initDicts() {
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "3"
                    })
                    .then(res => {
                        this.unitList = res.detail;
                    });
            },
            initCount() {
                this.$http.post("/schedule/countList").then(res => {
                    this.statusList = res.detail;
                });
                this.$http.post("/transport/track/completion").then(res => {
                    this.header.date = res.detail.date;
                    this.header.finish = res.detail.finish;
                    this.header.inTransit = res.detail.inTransit;
                    this.header.wait = res.detail.wait;
                });
            },
            formatDrivers(list) {
                if (list && list.length) {
                    return list.map(v => v.cName).join(",");
                }
                return "-";
            }
        },
        created() {
            this.setOperateList();
            this.initCount();
            this.initDicts();
            this.getList();
        },
        data() {
            return {
                loading: false,
                tableData: [],
                total: 0,

                header: {
                    date: "",
                    finish: 0,
                    inTransit: 0,
                    wait: 0
                },

                form: {
                    scheduleId: "",
                    status: "",
                    unitId: "",
                    plateNum: "",
                    driverNa: "",
                    planT: []
                },
                unitList: [],
                statusList: [],

                pageIndex: 1,
                pageSize: 10,

                operateList: [],

                btnMenuId: "wastesDispatchTrack"
            };
        },
        components: {
            ExportExcel
        }
    };
</script>
